import React from "react"
import PropTypes from "prop-types"
import sanitizeHtml from "sanitize-html"

import styles from "./styles.module.css"

const ImageCaption = ({ children }) => (
  <div
    className={styles.imageCaption}
    dangerouslySetInnerHTML={{
      __html: sanitizeHtml(children),
    }}
  />
)

export default ImageCaption

ImageCaption.propTypes = {
  children: PropTypes.node.isRequired,
}
