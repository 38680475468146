import React from "react"
import PropTypes from "prop-types"
import { graphql, Link } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Hero from "../components/Hero"
import FeaturedListItem from "../components/FeaturedListItem"
import NewsList from "../components/NewsList"
import LinkButton from "../components/LinkButton"
import BookList from "../components/BookList"
import * as inlineStyles from "../components/Layout/inlineStyles"
import styles from "./index.module.css"

const IndexPage = props => {
  const indexPage = props.data.wordpressPage
  const books = props.data.allWordpressWpBooks.edges.map(
    bookItem => bookItem.node
  )
  const posts = props.data.allWordpressPost.edges.map(postItem => postItem.node)

  return (
    <Layout>
      <SEO title="Hem" keywords={[`opal`]} />
      <Hero
        title={indexPage.acf.hero.title}
        linkTo={indexPage.acf.hero.link}
        excerpt={indexPage.acf.hero.excerpt}
        cover={indexPage.acf.hero.cover}
        image={indexPage.acf.hero.image}
      />
      <div className="container" style={inlineStyles.container}>
        <ul className={styles.featuredList}>
          <FeaturedListItem
            title={indexPage.acf.featuredItem1.title}
            linkTo={indexPage.acf.featuredItem1.link}
            excerpt={indexPage.acf.featuredItem1.excerpt}
            featuredImage={indexPage.acf.featuredItem1.image}
          />
          <FeaturedListItem
            title={indexPage.acf.featuredItem2.title}
            linkTo={indexPage.acf.featuredItem2.link}
            excerpt={indexPage.acf.featuredItem2.excerpt}
            featuredImage={indexPage.acf.featuredItem2.image}
          />
          <FeaturedListItem
            title={indexPage.acf.featuredItem3.title}
            linkTo={indexPage.acf.featuredItem3.link}
            excerpt={indexPage.acf.featuredItem3.excerpt}
            featuredImage={indexPage.acf.featuredItem3.image}
          />
        </ul>
        <div className={styles.newBooks}>
          <Link to="/bocker">
            <h3 className={styles.newBooksTitle}>Nya böcker</h3>
          </Link>
          <BookList books={books} />
        </div>
      </div>
      <div className={styles.news}>
        <div className={styles.newsListContainer}>
          <h2 className={styles.newsTitle}>Aktuellt</h2>
          <NewsList posts={posts} />
          <div className={styles.moreNews}>
            <LinkButton to="/press">Fler nyheter</LinkButton>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage

IndexPage.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array,
}

export const pageQuery = graphql`
  {
    wordpressSiteMetadata {
      url
    }
    allWordpressPost(sort: { fields: date, order: DESC }, limit: 3) {
      edges {
        node {
          id
          slug
          title
          date
          excerpt
          content
          date
          acf {
            featuredImage {
              altText: alt_text
              localFile {
                childImageSharp {
                  fluid(maxWidth: 384, maxHeight: 384) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
    wordpressPage(slug: { eq: "index" }) {
      acf {
        hero {
          title
          excerpt
          link
          image {
            altText: alt_text
            caption
            localFile {
              childImageSharp {
                fluid(
                  maxWidth: 1024
                  traceSVG: { color: "rgba(0, 169, 197, 0.1)" }
                ) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          cover {
            altText: alt_text
            caption
            localFile {
              childImageSharp {
                fluid(
                  maxWidth: 384
                  traceSVG: { color: "rgba(0, 169, 197, 0.1)" }
                ) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        featuredItem1: featured_item_1 {
          title
          excerpt
          link
          image {
            altText: alt_text
            caption
            localFile {
              childImageSharp {
                fluid(
                  maxWidth: 384
                  traceSVG: { color: "rgba(0, 169, 197, 0.1)" }
                ) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        featuredItem2: featured_item_2 {
          title
          excerpt
          link
          image {
            altText: alt_text
            caption
            localFile {
              childImageSharp {
                fluid(maxWidth: 384) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        featuredItem3: featured_item_3 {
          title
          excerpt
          link
          image {
            altText: alt_text
            caption
            localFile {
              childImageSharp {
                fluid(maxWidth: 384) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    allWordpressWpBooks(
      sort: { order: DESC, fields: acf___editions___release_date }
      limit: 6
    ) {
      edges {
        node {
          id
          title
          slug
          acf {
            creators {
              creator {
                title: post_title
                acf {
                  firstName: first_name
                  lastName: last_name
                }
              }
            }
            editions {
              releaseDate: release_date
            }
            cover {
              altText: alt_text
              caption
              localFile {
                childImageSharp {
                  fluid(maxWidth: 384) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
