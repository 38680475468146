import React from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"

import Link from "../GatsbyLink"
import styles from "./styles.module.css"

const FeaturedListItem = ({ title, excerpt, featuredImage, linkTo }) => (
  <li className={styles.featuredListItem}>
    <Link to={`${linkTo}`}>
      <div className={styles.featuredItem}>
        <div className={styles.featuredImageContainer}>
          <div className={styles.featuredImage}>
            <Img
              src={featuredImage.localFile.childImageSharp.src}
              fluid={featuredImage.localFile.childImageSharp.fluid}
              alt={featuredImage.altText}
            />
          </div>
        </div>
        <div className={styles.content}>
          <h3 className={styles.title}>{title}</h3>
          <p className={styles.excerpt}>{excerpt}</p>
        </div>
      </div>
    </Link>
  </li>
)

export default FeaturedListItem

FeaturedListItem.propTypes = {
  title: PropTypes.string.isRequired,
  excerpt: PropTypes.string.isRequired,
  linkTo: PropTypes.string.isRequired,
}
