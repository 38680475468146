import { Link } from "gatsby"
import React from "react"
import PropTypes from "prop-types"

import styles from "./styles.module.css"

const LinkButton = ({ to, children }) => (
  <Link className={styles.linkButton} to={to}>
    {children}
  </Link>
)

export default LinkButton

LinkButton.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}
