import React from "react"
import PropTypes from "prop-types"
import Link from "../GatsbyLink"
import Img from "gatsby-image"
import BackgroundImage from "gatsby-background-image"

import ImageCaption from "../ImageCaption"

import * as inlineStyles from "../Layout/inlineStyles"
import styles from "./styles.module.css"

const Hero = ({ title, excerpt, cover, image, linkTo }) => (
  <div className={styles.hero}>
    <Link to={linkTo} style={{ textDecoration: `none` }}>
      <BackgroundImage
        className={styles.heroImage}
        style={{
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          height: `calc(61.80339887498547vh - 7rem)`,
          maxHeight: 480,
          minHeight: 240,
          position: `relative`,
        }}
        fluid={image.localFile.childImageSharp.fluid}
        backgroundColor={`rgba(0, 169, 197, 0.2)`}
      >
        {cover.caption ? <ImageCaption>{cover.caption}</ImageCaption> : null}
      </BackgroundImage>
      <div
        className={`container ${styles.heroItem}`}
        style={{
          ...inlineStyles.container,
          display: `flex`,
        }}
      >
        <div
          className={styles.featuredImageContainer}
          style={{
            position: `relative`,
            flex: 1,
          }}
        >
          <div
            className={styles.featuredImage}
            style={{
              position: `absolute`,
              width: `100%`,
              bottom: 0,
            }}
          >
            <Img
              src={cover.localFile.childImageSharp.src}
              fluid={cover.localFile.childImageSharp.fluid}
              alt={cover.altText}
            />
          </div>
        </div>
        <div
          className={styles.content}
          style={{
            flex: 2,
            padding: `0 1.75rem`,
            fontSize: `1.125rem`,
          }}
        >
          <h3 className={styles.title}>{title}</h3>
          <p>{excerpt}</p>
        </div>
      </div>
    </Link>
  </div>
)

export default Hero

Hero.propTypes = {
  title: PropTypes.string.isRequired,
  excerpt: PropTypes.string.isRequired,
  featuredImage: PropTypes.string,
  linkTo: PropTypes.string.isRequired,
}
